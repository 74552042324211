import React from 'react';

import LandingPage from './LandingPageV2';

function App() {
  return (
      <div>
        <LandingPage />
      </div>
  );
}

export default App;
