import React, { useState, useEffect, useRef } from 'react';

const AfterClub = () => {
    const [emailInput, setEmailInput] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState('unknown');
    const [isScrolled, setIsScrolled] = useState(false);
    const canvasRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const sourceParam = params.get('source') || 'unknown';
        setSource(sourceParam);

        // Track visit with source
        const trackVisit = async () => {
            try {
                await fetch(`/api/track-visit?source=${encodeURIComponent(sourceParam)}`, {
                    method: 'POST'
                });
            } catch (error) {
                console.error('Failed to track visit', error);
            }
        };

        trackVisit();

        // Setup subtle background effect
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            const renderFrame = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Very subtle background effect - just a few particles
                const time = Date.now() * 0.0005; // much slower animation

                ctx.globalAlpha = 0.2;

                // Draw just a few subtle particles
                for (let i = 0; i < 10; i++) {
                    const x = Math.sin(time + i) * canvas.width * 0.4 + canvas.width * 0.5;
                    const y = Math.cos(time + i) * canvas.height * 0.4 + canvas.height * 0.4;
                    const size = 1 + Math.sin(time + i) * 0.5;

                    ctx.fillStyle = i % 3 === 0 ? '#39FF14' : '#ffffff';
                    ctx.globalAlpha = 0.1;

                    ctx.beginPath();
                    ctx.arc(x, y, size, 0, Math.PI * 2);
                    ctx.fill();
                }

                animationRef.current = requestAnimationFrame(renderFrame);
            };

            renderFrame();

            const handleResize = () => {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
            };

            window.addEventListener('resize', handleResize);

            return () => {
                cancelAnimationFrame(animationRef.current);
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`/api/collect-email?source=${encodeURIComponent(source)}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: emailInput }),
            });

            const result = await response.json();
            if (response.status === 200) {
                setSubmitted(true);
                setEmailInput('');

                // Simple flash effect for success
                if (canvasRef.current) {
                    const ctx = canvasRef.current.getContext('2d');
                    ctx.fillStyle = '#39FF14';
                    ctx.globalAlpha = 0.2;
                    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                    setTimeout(() => {
                        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                    }, 300);
                }
            } else {
                alert(result.error);
            }
        } catch (error) {
            alert('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const ShareButtons = () => {
        const shareText = "Found where the night continues. Amanecer - Barcelona's after hours.";
        const shareUrl = encodeURIComponent(window.location.href);

        return (
            <div className="flex justify-center space-x-4 pt-4">
                <a
                    href={`https://api.whatsapp.com/send?text=${encodeURIComponent(`${shareText} ${window.location.href}`)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-3 bg-black border border-white/10 hover:border-[#39FF14] transition-all duration-500"
                >
                    WhatsApp
                </a>
                <a
                    href={`https://t.me/share/url?url=${shareUrl}&text=${encodeURIComponent(shareText)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-3 bg-black border border-white/10 hover:border-[#39FF14] transition-all duration-500"
                >
                    Telegram
                </a>
            </div>
        );
    };

    const Loader = () => (
        <div className="flex justify-center items-center space-x-2">
            <div className="w-2 h-2 bg-[#39FF14] rounded-full animate-pulse"></div>
            <div className="w-2 h-2 bg-[#39FF14] rounded-full animate-pulse delay-150"></div>
            <div className="w-2 h-2 bg-[#39FF14] rounded-full animate-pulse delay-300"></div>
        </div>
    );

    return (
        <div className="min-h-screen bg-black text-white font-sans selection:bg-[#39FF14] selection:text-black relative">
            {/* Very subtle background canvas */}
            <canvas
                ref={canvasRef}
                className="fixed inset-0 z-0 pointer-events-none opacity-30"
            />

            {/* Scroll Indicator with minimal animation */}
            <div
                className={`fixed bottom-4 left-1/2 -translate-x-1/2 z-50 transition-all duration-300 
                    ${isScrolled ? 'opacity-0 pointer-events-none' : 'opacity-100'}
                `}
            >
                <div className="animate-bounce flex flex-col items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-8 h-8 text-[#39FF14]"
                        style={{
                            filter: 'drop-shadow(0 0 3px #39FF14)',
                        }}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                        />
                    </svg>
                    <span className="text-xs text-[#39FF14] mt-1">scroll</span>
                </div>
            </div>

            {/* Top Brand */}
            <div className="py-8">
                <h2
                    className="text-xl font-black tracking-tighter text-white/30 text-center"
                    style={{
                        fontFamily: 'monospace',
                        letterSpacing: '0.5em',
                    }}
                >AMANECER</h2>
            </div>

            {/* Hero Section with one animation */}
            <div className="min-h-[80vh] flex flex-col items-center justify-center relative overflow-hidden">
                <div className="z-10 px-6 space-y-6 text-center">
                    <h1 className="text-7xl sm:text-8xl font-black tracking-tighter relative">
                        <span
                            className="text-[#39FF14] relative inline-block"
                            style={{
                                filter: 'drop-shadow(0 0 8px rgba(57, 255, 20, 0.6))',
                            }}
                        >
                            AFTER
                            <span
                                className="absolute inset-0 text-pink-500 opacity-20"
                                style={{
                                    animation: 'glitch 8s infinite',
                                    animationDelay: 'random',
                                }}
                            >
                                AFTER
                            </span>
                        </span>
                        <span
                            className="text-white/80 animate-pulse"
                            style={{
                                animationDuration: '3s',
                            }}
                        >?</span>
                    </h1>
                </div>
            </div>

            {/* Map Section - static but with glow */}
            <div className="min-h-screen flex items-center justify-center">
                <div className="relative w-[90vw] max-w-[500px] h-[70vh] max-h-[500px] overflow-hidden">
                    <img
                        src="/Barcelona-map-min.png"
                        alt="Barcelona Map"
                        className="w-full h-full object-cover filter grayscale contrast-125 brightness-50 rounded-lg"
                    />
                    <div className="absolute inset-0 flex flex-col items-center justify-center z-10 space-y-2 bg-black/50 backdrop-blur-sm rounded-lg">
                        <div
                            className="text-3xl sm:text-4xl font-bold tracking-widest text-[#39FF14] animate-pulse"
                            style={{
                                animationDuration: '4s',
                                textShadow: '0 0 8px #39FF14',
                            }}
                        >
                            COMING SOON
                        </div>
                        <p className="text-white font-bold text-lg bg-black/50 px-3 py-1 rounded-sm tracking-widest">
                            BARCELONA
                        </p>
                    </div>
                </div>
            </div>

            {/* Time Section - static with glow */}
            <div className="min-h-screen flex items-center justify-center">
                <div className="max-w-sm mx-auto px-6 text-center">
                    <div className="space-y-4">
                        <p
                            className="text-5xl sm:text-6xl font-bold tracking-tight"
                            style={{
                                textShadow: '0 0 5px rgba(255,255,255,0.5)',
                                letterSpacing: '0.05em',
                            }}
                        >
                            05:00 — 12:00
                        </p>
                        <p className="text-zinc-400 text-base tracking-wider">
                            when clubs die, we rise
                        </p>
                    </div>
                </div>
            </div>

            {/* Email Signup - minimal glow effects */}
            <div className="min-h-screen flex items-center justify-center">
                <div className="max-w-md mx-auto px-6 w-full space-y-8">
                    <div className="space-y-2 text-center">
                        <p className="text-base text-white/70 tracking-widest">
                            want in?
                        </p>
                    </div>

                    {!submitted ? (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <input
                                type="email"
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                                placeholder="email"
                                className="w-full p-3 bg-black border border-white/10 text-white placeholder-white/30 focus:outline-none focus:border-[#39FF14] focus:ring-1 focus:ring-[#39FF14] transition-all text-base tracking-wide"
                                style={{
                                    backdropFilter: 'blur(5px)',
                                    boxShadow: emailInput ? `0 0 5px rgba(57, 255, 20, 0.3)` : 'none'
                                }}
                                required
                            />
                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full p-3 ${loading ? 'bg-white/10' : 'bg-[#39FF14]'} text-black font-medium transition-all text-base flex items-center justify-center tracking-wider`}
                                style={{
                                    boxShadow: `0 0 8px rgba(57, 255, 20, 0.6)`,
                                }}
                            >
                                {loading ? <Loader /> : 'GET NOTIFIED'}
                            </button>
                        </form>
                    ) : (
                        <div className="text-center space-y-4 animate-fadeIn">
                            <p
                                className="text-base tracking-wide"
                                style={{
                                    textShadow: `0 0 5px rgba(57, 255, 20, 0.5)`,
                                }}
                            >
                                You've joined the sleepless. Stay tuned.
                            </p>
                            <ShareButtons />
                        </div>
                    )}

                    <p className="text-center text-xs text-white/70 pt-4 italic tracking-wide">
                        by entering, you accept that sleep is for the weak
                    </p>
                </div>
            </div>

            {/* Bottom Brand */}
            <div className="py-8 px-6">
                <h2
                    className="text-xl font-black tracking-tighter text-white/30 text-center"
                    style={{
                        fontFamily: 'monospace',
                        letterSpacing: '0.5em',
                    }}
                >
                    AMANECER
                </h2>
                <p className="text-xs text-center text-white/30 pt-2 tracking-widest">
                    BCN
                </p>
            </div>

            {/* CSS for minimal animations */}
            <style jsx>{`
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(5px); }
                    to { opacity: 1; transform: translateY(0); }
                }

                @keyframes glitch {
                    0%, 5%, 95%, 100% { transform: translate(0); }
                    10% { transform: translate(-2px, 2px); }
                    15% { transform: translate(-2px, -2px); }
                    20% { transform: translate(2px, 2px); }
                    25% { transform: translate(2px, -2px); }
                    30% { transform: translate(0); }
                }

                .animate-fadeIn {
                    animation: fadeIn 0.8s ease-out forwards;
                }
            `}</style>
        </div>
    );
};

export default AfterClub;